import React, { useState, useRef, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarGrey from "../../images/calendar_grey.svg";
import calendarBrand from "../../images/calendar_brand.svg";
import { UpdateCaseContext } from "../../infrastructure/Context/UpdateCaseContext";
import moment from 'moment';

function FormDateInput({ fieldName, label, value, setValue, isLinkedWithAPI = true }: { fieldName: string, label: string, value: Date | null, setValue: (fieldName: string, value: string, hasValueChanged: boolean)
        => void, isLinkedWithAPI?: boolean }) {
    const [startDate, setStartDate] = useState(value ? new Date(value) : null);
    const [isModified, setIsModified] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const datePickerRef = useRef<any>(null);
    const [initialValue, setInitialValue] = useState(value);
    const { saveSuccess, setUnsavedChanges } = useContext(UpdateCaseContext);

    useEffect(() => {
        if (saveSuccess) {
            //const formattedStartDate = moment(startDate).format('DD-MM-YYYY');
            setInitialValue(startDate);
            setIsModified(false);
        }
    }, [saveSuccess, startDate]);

    useEffect(() => {
        const newValue = value ? moment(value) : null;
        if (newValue && startDate && !newValue.isSame(moment(startDate), 'day')) {
            const newDate = newValue.toDate();
            setStartDate(newDate);
            const isNewValueDifferentFromInitial = !newValue.isSame(moment(initialValue), 'day');
            setIsModified(isNewValueDifferentFromInitial);
            setUnsavedChanges(isNewValueDifferentFromInitial);
        }
    }, [value, initialValue, setUnsavedChanges, startDate]);

    function onChange(date : Date) {
        const formattedDate = moment(date).format('DD-MM-YYYY');
        setStartDate(date);

        const isDateDifferentFromInitial = !moment(date).isSame(moment(initialValue), 'day');
        setValue(fieldName, formattedDate, isDateDifferentFromInitial);
        setIsModified(isDateDifferentFromInitial);
        setUnsavedChanges(isDateDifferentFromInitial);
        closeDatePicker();
    }

    const openDatePicker = () => {
        setIsDatePickerOpen(true);
        datePickerRef.current?.setOpen(true);
    };

    const onCalendarClose = () => {
        setIsDatePickerOpen(false);
    };

    const closeDatePicker = () => {
        onCalendarClose();
        datePickerRef.current?.setOpen(false);
    };

    const labelClass = isLinkedWithAPI ? "input-header" : "input-header to-do";

    return (
        <div className="input-container">
            <label htmlFor={fieldName} className={labelClass}>
                {label?.toUpperCase()}
                {isModified && <span className="edit-indicator"></span>}
            </label>
            <div className="datepicker-wrapper">
                <DatePicker
                    ref={datePickerRef}
                    selected={startDate}
                    onChange={onChange}
                    onCalendarClose={onCalendarClose}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    dropdownMode= "scroll"
                    dateFormat="dd-MM-yyyy"
                    className="form-input customDatePickerInput"
                    calendarClassName="customDatePickerCalendar"
                    wrapperClassName="customDatePickerContainer"
                    popperClassName="customDatePickerPopper"
                    popperPlacement="bottom-end"
                    id={fieldName}
                />
                <img
                    className={`calendar-icon ${isDatePickerOpen ? 'open' : ''}`}
                    src={isDatePickerOpen ? calendarBrand : calendarGrey }
                    alt="icon calendar"
                    onClick={openDatePicker}
                />
            </div>
        </div>
    );
}
export default FormDateInput;