import React, {useContext} from "react";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormTextArea from "../Form/FormTextArea";
import FormReadonlyInput from "../Form/FormReadonlyInput";
import EFinanceFieldTypes from "../../models/Case/UpdateCase/FieldTypes/EFinanceFieldTypes";
import FormReadonly from "../Form/FormReadonlyInput";
import FormSelect from "../Form/FormSelect";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function Finance({id, title}: {id: string | undefined; title: string}) {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    if (!currentCase.CaseApp) {
      console.error("currentCase.CaseApp is null or undefined.");
      return;
    }

    switch (fieldName) {

      case EFinanceFieldTypes.NetClaim:
        currentCase.CaseApp.NetClaim = Number(value);
        break;
      case EFinanceFieldTypes.OriginalLoanAmount:
        currentCase.CaseApp.OriginalLoanAmount = Number(value);
        break;
      case ESummaryFieldTypes.CustomerCompletionFee:
        currentCase.CaseApp.CustomerCompletionFee = Number(value);
        break;
      case ESummaryFieldTypes.CustomerInitialFee:
        currentCase.CaseApp.CustomerInitialFee = value;
        break;
        case ESummaryFieldTypes.DailyRate:
          currentCase.CaseApp.InterestDaily = value;
        break;
      case ESummaryFieldTypes.MonthlyRate:
          currentCase.CaseApp.InterestMonthly = value;
        break;
      case ESummaryFieldTypes.LGDVPer:
          currentCase.CaseApp.LGDVPer = value;
        break;
        case ESummaryFieldTypes.ExpectedLTV:
          currentCase.CaseApp.ExpectedLTV = value;
        break;
      case ESummaryFieldTypes.YearlyRate:
          currentCase.CaseApp.InterestYearly = Number(value);
        break;
      case ESummaryFieldTypes.RolledUpInterestForTerm:
        currentCase.CaseApp.RolledUpInterestForTerm = Number(value);
        break;
      case ESummaryFieldTypes.BrokerAdminFee:
          currentCase.CaseApp.BrokerAdminFee = Number(value);
        break;
        case ESummaryFieldTypes.ValuationFee:
          currentCase.CaseApp.ValuationFee = Number(value);
        break;
        case ESummaryFieldTypes.LegalFee:
          currentCase.CaseApp.LegalFee = Number(value);
        break;
        case ESummaryFieldTypes.Deposit:
          currentCase.CaseApp.Deposit = Number(value);
        break;
        case ESummaryFieldTypes.OtherFee:
          currentCase.CaseApp.OtherFee = Number(value);
        break;
      case ESummaryFieldTypes.TotalRepaymentAmount:
        currentCase.CaseApp.TotalRepaymentAmount = value;
        break;

      case ESummaryFieldTypes.AdditionalFee1:
        currentCase.CaseApp.updateAdditionalFee(1, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee2:
        currentCase.CaseApp.updateAdditionalFee(2, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee3:
        currentCase.CaseApp.updateAdditionalFee(3, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee4:
        currentCase.CaseApp.updateAdditionalFee(4, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee5:
        currentCase.CaseApp.updateAdditionalFee(5, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee6:
        currentCase.CaseApp.updateAdditionalFee(6, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee7:
        currentCase.CaseApp.updateAdditionalFee(7, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee9:
        currentCase.CaseApp.updateAdditionalFee(9, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee10:
        currentCase.CaseApp.updateAdditionalFee(10, Number(value));
        break;

      case ESummaryFieldTypes.LastLoanInterestRaisedAmount:
        currentCase.CaseApp.LastLoanInterestRaisedAmount = value;
        break;

        case ESummaryFieldTypes.TotalLoanInterestRaisedAmount:
        currentCase.CaseApp.TotalLoanInterestRaisedAmount = value;
        break;

      case ESummaryFieldTypes.CaseAssessorQ37:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(37, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ38:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(38, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ39:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(39, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ40:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(40, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ41:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(41, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ42:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(42, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ43:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(43, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ44:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(44, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ45:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(45, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ46:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(46, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ47:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(47, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ48:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(48, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ49:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(49, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ50:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(50, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ51:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(51, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ52:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(52, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ53:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(53, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ54:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(54, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ55:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(55, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ56:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(56, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ57:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(57, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ58:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(58, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ59:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(59, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ60:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(60, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ61:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(61, value);
        break;

        case ESummaryFieldTypes.CaseAssessorA481:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(481, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("FinancialInformation", hasValueChanged);
    setUnsavedChanges(hasValueChanged);
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormReadonly fieldName={ESummaryFieldTypes.LGDVPer.toString()} label="LTGDV" value={currentCase.CaseApp?.LGDVPer?.toString()} />
        <FormCurrencyInput readonly={true} fieldName={EFinanceFieldTypes.NetClaim.toString()} label="Gross Loan" value={currentCase.CaseApp?.NetClaim?.toString() ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.CustomerInitialFee.toString()} label="Arrangement fee" value={currentCase.CaseApp?.CustomerInitialFee?.toString() ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.AdditionalFee6.toString()} label="Broker fee" value={currentCase.CaseApp?.getAdditionalFee(6)?.toString() || ''} setValue={handleState} />
        <FormDecimalInput readonly={true} fieldName={ESummaryFieldTypes.MonthlyRate.toString()} label="Monthly rate"  value={currentCase.CaseApp?.InterestMonthly?.toString() ?? ""} setValue={handleState}/>
        <FormCurrencyInput fieldName={ESummaryFieldTypes.BrokerAdminFee.toString()} label="Rolled up interest for term" value={currentCase.CaseApp?.BrokerAdminFee?.toString()  ?? ""} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.ValuationFee.toString()} label="Total Drawdown Tranche" value={currentCase.CaseApp?.ValuationFee?.toString() ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.OtherFee.toString()} label="Title Insurance Fee" value={currentCase.CaseApp?.OtherFee?.toString() ?? ""} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee1.toString()} label="Application Fee"  value={currentCase.CaseApp?.getAdditionalFee(1)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee3.toString()} label="Valuation Fee" value={currentCase.CaseApp?.getAdditionalFee(3)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee5.toString()} label="QS/AM interim site visit inc VAT" value={currentCase.CaseApp?.getAdditionalFee(5)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee9.toString()} label="Drawdown Admin Fee" value={currentCase.CaseApp?.getAdditionalFee(9)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput readonly={true}  fieldName={ESummaryFieldTypes.BrokerAdminFee.toString()} label="Interest when retained"  value={currentCase.CaseApp?.BrokerAdminFee?.toString()} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.LastLoanInterestRaisedAmount.toString()} label="Interest payable per month" value={currentCase.CaseApp?.LastLoanInterestRaisedAmount} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.TotalLoanInterestRaisedAmount.toString()} label="Total Interest Posted" value={currentCase.CaseApp?.TotalLoanInterestRaisedAmount} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ37.toString()} label="Post Part Release Gross Loan Limit on Interest Charged" value={currentCaseAssessorQuestions.GetQuestionAnswer(37)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ39.toString()} label="Anticipated Total IRR" value={currentCaseAssessorQuestions.GetQuestionAnswer(39)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ41.toString()} label="Funder IRR" value={currentCaseAssessorQuestions.GetQuestionAnswer(41)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ43.toString()} label="9Y Total IRR %" value={currentCaseAssessorQuestions.GetQuestionAnswer(43)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ45.toString()} label="Drawdown request from funder" value={currentCaseAssessorQuestions.GetQuestionAnswer(45)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ47.toString()} label="Part & Part Payment Due Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(47)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ49.toString()} label="Estimated Income" value={currentCaseAssessorQuestions.GetQuestionAnswer(49)} setValue={handleState} />
        {/* <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ51.toString()} label="Actual IRR" value={currentCaseAssessorQuestions.GetQuestionAnswer(51)} setValue={handleState} /> */}
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ52.toString()} label="Lender A Peak Funding Balance" value={currentCaseAssessorQuestions.GetQuestionAnswer(52)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ53.toString()} label="Part and Part - No of Months Serviced" value={currentCaseAssessorQuestions.GetQuestionAnswer(53)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ54.toString()} label="Interest Type" value={currentCaseAssessorQuestions.GetQuestionAnswer(54)} setValue={handleState}  values={currentCaseAssessorQuestions.GetQuestionAnswerList(54)} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ55.toString()} label="Actual 9Y Total IRR" value={currentCaseAssessorQuestions.GetQuestionAnswer(55)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ56.toString()} label="Actual Funding Balance" value={currentCaseAssessorQuestions.GetQuestionAnswer(56)} setValue={handleState} />


        </div>
        <div className="form-column">
        <FormDecimalInput readonly={true} fieldName="LTV (Day 1 gross loan)" label="LTV (Day 1 gross loan)" value={currentCase.CaseApp?.ExpectedLTV?.toString()   ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={EFinanceFieldTypes.OriginalLoanAmount.toString()} label="Original Loan Amount" value={currentCase.CaseApp?.OriginalLoanAmount?.toString()  ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.CustomerCompletionFee.toString()} label="Exit Fee" value={currentCase.CaseApp?.CustomerCompletionFee?.toString()} setValue={handleState} />
        <FormDecimalInput readonly={true} fieldName={ESummaryFieldTypes.DailyRate.toString()} label="Daily rate"  value={currentCase.CaseApp?.InterestDaily?.toString() ?? ""} setValue={handleState}/>
        <FormDecimalInput readonly={true} fieldName={ESummaryFieldTypes.YearlyRate.toString()} label="Yearly rate" value={currentCase.CaseApp?.InterestYearly?.toString() ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.TotalRepaymentAmount.toString()} label="Total repayment amount" value={currentCase.CaseApp?.TotalRepaymentAmount} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.LegalFee.toString()} label="Legal Fee inc VAT" value={currentCase.CaseApp?.LegalFee?.toString()  ?? ""} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.Deposit.toString()} label="Legal Disbursements" value={currentCase.CaseApp?.Deposit?.toString()  ?? ""} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee2.toString()} label="Commitment Fee" value={currentCase.CaseApp?.getAdditionalFee(2)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee4.toString()} label="QS/AM initial report fee inc VAT" value={currentCase.CaseApp?.getAdditionalFee(4)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee7.toString()} label="Broker day 1 Commission" value={currentCase.CaseApp?.getAdditionalFee(7)?.toString() || ''} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee10.toString()} label="Redemption Admin Fee"  value={currentCase.CaseApp?.getAdditionalFee(10)?.toString() || ''} setValue={handleState} />
        {/* <FormCurrencyInput fieldName={ESummaryFieldTypes.AdditionalFee7.toString()} label="Total Gross Loan Interest" value={currentCase.CaseApp?.getAdditionalFee(7)?.toString() || ''} setValue={handleState} /> */}
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorA481.toString()} label="Monthly Capital Repayment" value={currentCaseAssessorQuestions.GetQuestionAnswer(481)} setValue={handleState} />
        <FormCurrencyInput readonly={true} fieldName={ESummaryFieldTypes.LastLoanInterestRaisedAmount.toString()} label="Latest interest posted" value={currentCase.CaseApp?.LastLoanInterestRaisedAmount?.toString()   ?? ""} setValue={handleState}  />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ38.toString()} label="Net Yield" value={currentCaseAssessorQuestions.GetQuestionAnswer(38)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ40.toString()} label="Drawdown Start Month" value={currentCaseAssessorQuestions.GetQuestionAnswer(40)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ42.toString()} label="Investor Total IRR" value={currentCaseAssessorQuestions.GetQuestionAnswer(42)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ44.toString()} label="Average Funding Balance" value={currentCaseAssessorQuestions.GetQuestionAnswer(44)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ46.toString()} label="Serviced Payment Due Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(46)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ48.toString()} label="Default Payment Due Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(48)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ50.toString()} label="Actual Income Received" value={currentCaseAssessorQuestions.GetQuestionAnswer(50)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ51.toString()} label="Actual Total IRR" value={currentCaseAssessorQuestions.GetQuestionAnswer(51)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ57.toString()} label="Spare 6" value={currentCaseAssessorQuestions.GetQuestionAnswer(57)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ58.toString()} label="Spare 7" value={currentCaseAssessorQuestions.GetQuestionAnswer(58)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ59.toString()} label="Sparne 8" value={currentCaseAssessorQuestions.GetQuestionAnswer(59)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ60.toString()} label="Spare 9" value={currentCaseAssessorQuestions.GetQuestionAnswer(60)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ61.toString()} label="Spare 10" value={currentCaseAssessorQuestions.GetQuestionAnswer(61)} setValue={handleState} />
      </div>
    </div>
  );
}
