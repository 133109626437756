import moment from "moment";

export default class SecurityCustomField {
    public CustomFieldNo: string = '';
    public CustomFieldValue: string = '';
    
    constructor(CustomFieldNo: string = '', CustomFieldValue: string = '') {
        this.CustomFieldNo = CustomFieldNo;
        this.CustomFieldValue = CustomFieldValue;
    }
  }