import React, { useState, useEffect, useRef, useContext } from 'react';
import iconClose from '../../images/iconClose.svg';
import '../../styles/FormTextArea.css';
import { UpdateCaseContext } from '../../infrastructure/Context/UpdateCaseContext';

function FormTextArea({ fieldName, label, value, setValue, iterator = 0, isLinkedWithAPI = true }: { 
  fieldName: string, 
  label: string, 
  value: string, 
  iterator?: number,
  setValue: (fieldName: string, value: string, hasValueChanged: boolean, iterator?: number) => void, 
  isLinkedWithAPI?: boolean}) {
  const [inputValue, setInputValue] = useState(value || '');
  const [isModified, setIsModified] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const { saveSuccess, setUnsavedChanges } = useContext(UpdateCaseContext);
  const initialRender = useRef(true);
 
  useEffect(() => {
    if (saveSuccess) {
      setIsModified(false);
      setInitialValue(inputValue);
      setUnsavedChanges(false);
    }
  }, [saveSuccess, inputValue, setUnsavedChanges]);
 
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setInputValue(value || '');
      setIsModified(false);
      setUnsavedChanges(value !== initialValue);
    }
  }, [value, initialValue, setUnsavedChanges]);
 
  useEffect(() => {
    setIsModified(inputValue !== initialValue && inputValue !== '' && initialValue !== undefined);
  }, [inputValue, initialValue]);

  useEffect(() => {
    const textArea = document.getElementById(fieldName) as HTMLTextAreaElement;
    if (textArea) {
      textArea.rows = 1;
      textArea.style.height = 'auto';
      const scrollHeight = textArea.scrollHeight;
      if (inputValue === '') {
        textArea.style.height = '30px';
      } else {
        textArea.style.height = `${scrollHeight}px`;
      }
    }
  }, [inputValue, fieldName]);

  function onChange(value: string) {
    let hasValueChanged = true;
    if (value !== inputValue) {
      setIsModified(true);
      setUnsavedChanges(true);
    }
    else {
      setIsModified(false);
      setUnsavedChanges(false);
      hasValueChanged = false;
    }
    setInputValue(value);
    setValue(fieldName, value, hasValueChanged, iterator);
  }
  const labelClass = isLinkedWithAPI ? "input-header" : "input-header to-do";

  return (
    <div className="textarea-input-container">
      <label htmlFor={fieldName} className={labelClass}>
        {label?.toUpperCase()}
        {isModified && <span className="edit-indicator"></span>}
      </label>
      <textarea
        value={inputValue}
        className="form-textarea-custom"
        id={fieldName}
        onChange={(e) => onChange(e.target.value)}
      ></textarea>
    </div>
  );
}

export default FormTextArea;