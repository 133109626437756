import React from 'react';
import {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function Summary() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, saveSuccess, setUnsavedChanges} = useContext(UpdateCaseContext);
  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.DynamicDataField2:
        currentCase.UpdateDynamicDataField(2, value);
        break;
      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

      case ESummaryFieldTypes.DynamicDataField8:
        currentCase.UpdateDynamicDataField(8, value);
        break;

      case ESummaryFieldTypes.DynamicDataField9:
        currentCase.UpdateDynamicDataField(9, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ1:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(1, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ2:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(2, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ3:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(3, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ4:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(4, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ5:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(5, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ6:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(6, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ7:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(7, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ8:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(8, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ9:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(9, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ10:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(10, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ11:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(11, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ12:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(12, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ13:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(13, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ14:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(14, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ15:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(15, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ16:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(16, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ17:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(17, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ18:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(18, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ19:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(19, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ20:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(20, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ21:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(21, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ22:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(22, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ23:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(23, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ24:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(24, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ25:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(25, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ26:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(26, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ27:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(27, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ28:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(28, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ29:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(29, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ30:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(30, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ31:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(31, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ32:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(32, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ33:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(33, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ34:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(34, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ35:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(35, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ36:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(36, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("Summary", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
      <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.DynamicDataField8.toString()}
          label="RAG Status"
          value={currentCase.GetDynamicDataField(8)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(8)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ1.toString()}
          label="Repeat Customer"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(1)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(1)}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ3.toString()}
          label="Total Exposure to Client # of cases"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(3)}
          setValue={handleState}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ5.toString()} label="Customer Grouping" value={currentCaseAssessorQuestions.GetQuestionAnswer(5)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ6.toString()}
          label="Current Exit Strategy"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(6)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(6)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ8.toString()} label="Estimated redemption date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(8)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ10.toString()}
          label="Extension / Overrun contracted redemption date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(10)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ12.toString()} label="Last inspection date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(12)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ14.toString()}
          label="Bi-monthly Last inspection date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(14)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ16.toString()}
          label="1-month after completion on drawdown loans"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(16)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ18.toString()} label="Midway reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(18)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ20.toString()} label="3-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(20)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ22.toString()} label="1-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(22)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ24.toString()} label="Loan expired letter" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(24)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ26.toString()} label="No contact day 7" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(26)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ28.toString()} label="No contact day 21" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(28)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ30.toString()}
          label="No contact instruct QS/AM day 25"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(30)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ32.toString()}
          label="No contact QS/AM report due back"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(32)}
          setValue={handleState}
        />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ35.toString()} label="Enquiry Rating" value={currentCaseAssessorQuestions.GetQuestionAnswer(35)} setValue={handleState} values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(35)} />
    </div>
        <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.DynamicDataField9.toString()}
          label="RAG Reason"
          value={currentCase.GetDynamicDataField(9)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(9)}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ2.toString()}
          label="Total Exposure to Client £"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(2)}
          setValue={handleState}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ4.toString()} label="Other case reference numbers" value={currentCaseAssessorQuestions.GetQuestionAnswer(4)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.DynamicDataField2.toString()}
          label="Proposed Exit Strategy"
          value={currentCase.GetDynamicDataField(2)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(2)}
        />
        {/* <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ7.toString()}
          label="Actual Exit Strategy"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(7)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(7)}
        /> */}
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ255.toString()} label="Contracted Redemption Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(255)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ11.toString()}
          label="Extension next contact date required"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(11)}
          setValue={handleState}
        />
        {/* <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ13.toString()} label="Last drawdown date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(13)} setValue={handleState} /> */}
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ15.toString()} label="Welcome letter issued date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(15)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ17.toString()}
          label="1-month before Part & Part Serviced date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(17)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ19.toString()} label="5-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(19)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ21.toString()} label="2-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(21)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ23.toString()} label="2-week reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(23)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ25.toString()} label="Default interest due" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(25)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ27.toString()} label="No contact day 14" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(27)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ29.toString()} label="No contact day 24" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(29)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ31.toString()}
          label="No contact QS/AM site visit date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(31)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ33.toString()}
          label="No site visit monitoring fee £250"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(33)}
          setValue={handleState}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ34.toString()} label="Latest Note" value={currentCaseAssessorQuestions.GetQuestionAnswer(34)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ36.toString()} label="Confidence Rating" value={currentCaseAssessorQuestions.GetQuestionAnswer(36)} setValue={handleState} values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(36)}/>
      </div>
    </div>
  );
}
