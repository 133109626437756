import React from "react";
import iconHome from "../images/iconHome.svg";

interface IProps {
  pageName: string;
}

const CasesBreadcrumbs = ({pageName}: IProps) => {
  function showCorrectCrumbs() {
    if (pageName == "Cases") {
      return (
        <div className="bc-wrapper">
          <a href="/">
          <img src={iconHome} />
          </a>
          <p>{">"}</p>
          <a href="/">
          <p className="non-active">Cases</p>
          </a>
          {/* <p className="non-active">Cases</p> */}
          <p>{">"}</p>
          <p className="active">Grid</p>
        </div>
      );
    } else if (pageName == "KnowYourCustomer") {
      return (
        <div className="bc-wrapper">
          <a href="/">
          <img src={iconHome} />
          </a>
        <p>{">"}</p>
        <a href="/">
          <p className="non-active">Cases</p>
          </a>
        {/* <p className="non-active">Cases</p> */}
        <p>{">"}</p>
        <p className="non-active">Summary</p>
        <p>{">"}</p>
        <p className="active">KYC</p>
      </div>
      )
    }
  }

  return (
    <>
      {showCorrectCrumbs()}
    </>
  );
};

export default CasesBreadcrumbs;
