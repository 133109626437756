import UserInfo from "../../User/UserInfo";
import CaseApp from "../CaseApp";
import DynamicFieldDetail from "../DynamicFieldDetail";

export default class Case {
    UserInfo: UserInfo | null;
    CaseApp: CaseApp | null;

    constructor(userInfo: UserInfo | null, caseApp: CaseApp | null) {
        this.UserInfo = userInfo;
        this.CaseApp = caseApp;
    }

    UpdateDynamicDataField(fieldNo: number, value: string) {
        var dynamicDataField = this.CaseApp?.DynamicFieldDetail.find((z) => z.FieldNo == fieldNo);
        if (dynamicDataField)
          dynamicDataField.FieldValue = value;
        else
          this.CaseApp?.DynamicFieldDetail.push(new DynamicFieldDetail(fieldNo, value));
    }
    
    GetDynamicDataField(fieldNo: number) {
        var DynamicDataFieldValue = "";
        var dynamicDataField = this.CaseApp?.DynamicFieldDetail.find((z) => z.FieldNo == fieldNo);
        if (dynamicDataField)
          DynamicDataFieldValue = dynamicDataField.FieldValue;
    
        return DynamicDataFieldValue;
    }
}