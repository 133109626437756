import React, { useContext, useEffect, useRef, useState } from "react";
import iconClose from "../../images/iconClose.svg";
import { UpdateCaseContext } from "../../infrastructure/Context/UpdateCaseContext";
import { v4 as uuidv4 } from 'uuid';

function FormDecimalInput({
  fieldName,
  label,
  value,
  setValue,
  readonly = false,
  isLinkedWithAPI = true,
}: {
  fieldName: string;
  label: string;
  value: string | undefined;
  setValue: (fieldName: string, value: string, hasValueChanged: boolean) => void;
  readonly?: boolean;
  isLinkedWithAPI?: boolean;
}) {
  const [inputValue, setInputValue] = useState(value ?? '');
  const [isModified, setIsModified] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const { saveSuccess, setUnsavedChanges } = useContext(UpdateCaseContext);
  const initialRender = useRef(true);
  const [inputId] = useState(() => uuidv4());

  useEffect(() => {
    if (saveSuccess) {
      setIsModified(false);
      setInitialValue(inputValue);
      setUnsavedChanges(false);
    }
  }, [saveSuccess, inputValue, setUnsavedChanges]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (value !== undefined && value !== initialValue) {
        setInputValue(value);
        setIsModified(false);
        setUnsavedChanges(value !== initialValue);
      }
    }
  }, [value, initialValue, setUnsavedChanges]);

  useEffect(() => {
    setIsModified(inputValue !== initialValue && inputValue !== '' && initialValue !== undefined);
  }, [inputValue, initialValue]);

  function onChange(value: string) {
    const regex = /^-?[0-9]*(\.[0-9]{0,5})?$/;
    if (regex.test(value) || value === '') {
      setInputValue(value);
      let hasValueChanged = true;
      if (value !== initialValue) {
        setIsModified(true);
        setUnsavedChanges(true);
      } else {
        setIsModified(false);
        setUnsavedChanges(false);
        hasValueChanged = false;
      }
      setValue(fieldName, value, hasValueChanged);
    }
  }

  const labelClasses = `input-header ${!isLinkedWithAPI ? 'to-do' : ''}`;

  return (
    <div className="input-container">
      <label htmlFor={inputId}  className={labelClasses}>
        {label?.toUpperCase()}
        {isModified && <span className="edit-indicator"></span>}
      </label>
      <input
        value={inputValue ? parseFloat(inputValue).toFixed(2) : ''}
        name={fieldName}
        type="text"
        readOnly={readonly}
        className={readonly ? "form-input form-input-readonly" : "form-input"}
        id={inputId}
        onChange={(e) => onChange(e.target.value)}
      />
      {!readonly && (
        <img
            className="clear-val-btn"
            src={iconClose}
            alt="Clear input"
            onClick={() => onChange("")}
        />
      )}
    </div>
  );
}

export default FormDecimalInput;
