import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import brightOfficeLogo from "../images/BrightOffice_logo.png";
import "../styles/Login.css";
import Cookies from "js-cookie";
import packageJson from './../../package.json';

interface LoginRequest {
  Options: LoginRequestOptions;
}

interface LoginRequestOptions {
  Username: string;
  Password: string;
  OSName: string;
  existingSessionKey: string;
  deviceUniqueId: string;
  softwareLoginScreenVersion: string;
  OverrideLoginUrl: string;
  ScreenSize: string;
}

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);

  const navigate = useNavigate();

  async function checkCredentials(username: string, password: string) {
    let loginRequest: LoginRequest = {
      Options: {
        Username: username,
        Password: password,
        existingSessionKey: "",
        OSName: "",
        OverrideLoginUrl: "",
        ScreenSize: "",
        softwareLoginScreenVersion: "",
        deviceUniqueId: "",
      },
    };

    const request: RequestInit = {
      method: "POST",
      body: JSON.stringify(loginRequest),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch("https://my9ycap.co.uk/CheckMails.asmx/LoginMobileV3", request).then(async (response) => {
      if (!response.ok) {
        return "";
      }

      return await response.json();
    });

    if (response) {
      return true;
    } else {
      return false;
    }
  }

  async function login(e: React.FormEvent<EventTarget>) {
    e.preventDefault();
    if (await checkCredentials(username, password)) {
      Cookies.set("Credentials", `${username}|${password}`, {expires: 7});
      navigate("/");
      setLoginFailed(false);
    } else {
      setLoginFailed(true);
    }
  }

  return (
    <>
      <div className="login-page-wrapper">
        <img src={brightOfficeLogo} alt="Bright Office logo" />
        <label htmlFor="username">USERNAME</label>
        <input className="username-input" type="text" placeholder="" id="username" onChange={(e) => setUsername(e.target.value)}/>
        <label htmlFor="password">PASSWORD</label>
        <input className="password-input" type="password" placeholder="" id="password" onChange={(e) => setPassword(e.target.value)}/>
        <button className="sign-in-btn" onClick={login}>
          Sign in
        </button>
        <br /><br />
        {/* <label htmlFor="version">VERSION 0.1.2</label> */}
      </div>
      <div id="package-version">{packageJson.version}</div>
      {/* <label htmlFor="version">VERSION 0.1.3</label> */}
      {loginFailed ? (
        <div className="error-msg-wrapper">
          <label className="error-msg-header">Wrong username or password</label>
          <label className="error-msg-prompt">Check your username password again</label>
        </div>
        
      ) : (
        <></>
      )}
    </>
  );
}

export default Login;
