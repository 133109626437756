import React from "react";
import BrightOfficeTopLogo from "../images/BO_logo_top_bar.png";
import casesIcon from "../images/casesIcon.svg";
import "../styles/Cases.css";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

const CasesTopBar = () => {

  const navigate = useNavigate();

  function logout() {
    Cookies.remove("Credentials");
    navigate("/login");
  }
  return (
    <div className="top-bar-wrapper">
      <div className="top-bar-logo">
        <img src={BrightOfficeTopLogo} />
      </div>
      <div className="top-bar-nav">
        <div className="top-bar-nav-content">
          <img src={casesIcon} />
          <p>Cases</p>
        </div>
      </div>
      <div className="top-bar-logout" onClick={logout}>
        <div className="top-bar-logout-content">
          <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.7952 2.05714C4.09586 2.05714 2.71827 3.43866 2.71827 5.14286V18.8571C2.71827 20.5613 4.09586 21.9429 5.7952 21.9429H15.3678C17.0672 21.9429 18.4448 20.5613 18.4448 18.8571V17.4857C18.4448 16.9177 18.904 16.4571 19.4704 16.4571C20.0369 16.4571 20.4961 16.9177 20.4961 17.4857V18.8571C20.4961 21.6975 18.2001 24 15.3678 24H5.7952C2.96297 24 0.666992 21.6975 0.666992 18.8571V5.14286C0.666992 2.30254 2.96297 0 5.7952 0H15.3678C18.2001 0 20.4961 2.30254 20.4961 5.14286V6.51429C20.4961 7.08235 20.0369 7.54286 19.4704 7.54286C18.904 7.54286 18.4448 7.08235 18.4448 6.51429V5.14286C18.4448 3.43866 17.0672 2.05714 15.3678 2.05714H5.7952ZM13.358 5.78698C13.7586 6.18866 13.7586 6.83991 13.358 7.2416L9.63883 10.9714L26.308 10.9714C26.8745 10.9714 27.3337 11.4319 27.3337 12C27.3337 12.5681 26.8745 13.0286 26.308 13.0286L9.63883 13.0286L13.358 16.7584C13.7586 17.1601 13.7586 17.8113 13.358 18.213C12.9575 18.6147 12.3081 18.6147 11.9076 18.213L6.43748 12.7273C6.03694 12.3256 6.03694 11.6744 6.43748 11.2727L11.9076 5.78698C12.3081 5.38529 12.9575 5.38529 13.358 5.78698Z"
              fill="currentColor"
            />
          </svg>
          <p className="logout-btn">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default CasesTopBar;
