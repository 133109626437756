import React, {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function SignoffApprovals() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.CaseAssessorQ284:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(284, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ285:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(285, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ286:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(286, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ287:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(287, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ288:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(288, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ289:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(289, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ290:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(290, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ291:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(291, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ292:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(292, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ293:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(293, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ294:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(294, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ295:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(295, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ296:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(296, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ297:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(297, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ298:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(298, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ299:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(299, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ300:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(300, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ301:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(301, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ302:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(302, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ303:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(303, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ304:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(304, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ305:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(305, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ306:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(306, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ307:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(307, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ308:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(308, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ309:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(309, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ310:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(310, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ311:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(311, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ312:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(312, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ313:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(313, value);
        break;
      case ESummaryFieldTypes.CaseAssessorQ314:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(314, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ315:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(315, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ316:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(316, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ317:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(317, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ318:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(318, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ319:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(319, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ320:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(320, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ321:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(321, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ322:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(322, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ323:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(323, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ324:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(324, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ325:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(325, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ326:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(326, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ327:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(327, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ328:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(328, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ329:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(329, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ330:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(330, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ331:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(331, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ332:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(332, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ333:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(333, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ334:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(334, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ335:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(335, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ336:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(336, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ337:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(337, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ338:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(338, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ339:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(339, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ340:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(340, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ341:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(341, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ342:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(342, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ343:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(343, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ344:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(344, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ345:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(345, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ346:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(346, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ347:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(347, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ348:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(348, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ349:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(349, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ350:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(350, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ351:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(351, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ352:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(352, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ353:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(353, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ354:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(354, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ355:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(355, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ356:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(356, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ357:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(357, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ358:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(358, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ359:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(359, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ360:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(360, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ361:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(361, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ362:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(362, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ363:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(363, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ364:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(364, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ365:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(365, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ366:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(366, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ367:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(367, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ368:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(368, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ369:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(369, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ370:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(370, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ371:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(371, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ372:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(372, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ373:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(373, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ374:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(374, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ375:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(375, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ376:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(376, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ377:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(377, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ378:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(378, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ379:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(379, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ380:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(380, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ381:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(381, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ382:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(382, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ383:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(383, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ384:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(384, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ385:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(385, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ386:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(386, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ387:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(387, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ388:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(388, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ389:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(389, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ390:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(390, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ391:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(391, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ392:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(392, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ393:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(393, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ394:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(394, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ395:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(395, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ396:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(396, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ397:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(397, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ398:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(398, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ399:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(399, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ400:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(400, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ401:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(401, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ402:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(402, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ403:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(403, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ404:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(404, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ405:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(405, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ406:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(406, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ407:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(407, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ408:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(408, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ409:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(409, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ410:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(410, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ411:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(411, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ412:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(412, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ413:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(413, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ414:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(414, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ415:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(415, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ416:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(416, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ417:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(417, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ418:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(418, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ419:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(419, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ420:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(420, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ421:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(421, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ422:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(422, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ423:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(423, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ424:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(424, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ425:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(425, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ426:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(426, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ427:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(427, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ428:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(428, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ429:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(429, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ430:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(430, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ431:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(431, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ432:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(432, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ433:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(433, value);
        break;
        
      case ESummaryFieldTypes.CaseAssessorQ434:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(434, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("SignoffApprovals", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ284.toString()}
          label="HOT - Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(284)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(284)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ287.toString()}
          label="SAN - Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(287)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(287)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ289.toString()} label="SAN Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(289)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ291.toString()}
          label="SAN 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(291)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(291)}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ293.toString()}
          label="SAN 2nd Approver Reason for Supporting"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(293)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ295.toString()}
          label="SAN Funder Approval"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(295)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(295)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ297.toString()}
          label="COM - Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(297)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(297)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ299.toString()} label="COM decision summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(299)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ301.toString()}
          label="D1 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(301)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(301)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ303.toString()} label="D1 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(303)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ305.toString()} label="D2 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(305)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ307.toString()}
          label="D3 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(307)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(307)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ309.toString()} label="D3 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(309)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ311.toString()} label="D4 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(311)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ313.toString()}
          label="D5 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(313)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(313)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ315.toString()} label="D5 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(315)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ317.toString()} label="D6 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(317)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ319.toString()}
          label="D7 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(319)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(319)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ321.toString()} label="D7 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(321)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ323.toString()} label="D8 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(323)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ325.toString()}
          label="D9 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(325)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(325)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ327.toString()} label="D9 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(327)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ329.toString()} label="D10 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(329)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ331.toString()}
          label="D11 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(331)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(331)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ333.toString()} label="D11 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(333)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ335.toString()} label="D12 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(335)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ337.toString()}
          label="D13 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(337)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(337)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ339.toString()} label="D13 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(339)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ341.toString()} label="D14 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(341)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ343.toString()}
          label="D15 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(343)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(343)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ345.toString()} label="D15 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(345)} setValue={handleState} />
         <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ347.toString()} label="S106 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(347)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ349.toString()}
          label="DOE 1 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(349)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(349)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ351.toString()} label="DOE 1 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(351)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ353.toString()} label="DOE 2 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(353)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ355.toString()}
          label="DOP Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(355)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(355)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ357.toString()} label="DOP Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(357)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ359.toString()} label="Consent 1 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(359)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ361.toString()}
          label="Consent 2 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(361)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(361)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ363.toString()} label="Consent 2 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(363)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ365.toString()} label="Consent 3 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(365)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ367.toString()}
          label="Consent 4 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(367)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(367)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ369.toString()} label="Consent 4 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(369)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ371.toString()} label="Consent 5 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(371)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ373.toString()}
          label="Consent 6 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(373)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(373)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ375.toString()} label="Consent 6 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(375)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ377.toString()} label="2nd Charge Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(377)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ379.toString()}
          label="Part Release 1 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(379)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(379)}
        />
        <FormDateInput 
        fieldName={ESummaryFieldTypes.CaseAssessorQ381.toString()} 
        label="Part Release 1 Decision Date" 
        value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(381)} 
        setValue={handleState} 
        />

        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ383.toString()}
          label="Part Release 1 2nd Approver Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(383)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ385.toString()}
          label="Part Release 1 Funder Approval"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(385)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ387.toString()}
          label="Part Release 2 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(387)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(387)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ389.toString()} label="Part Release 2 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(389)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ391.toString()}
          label="Part Release 2 2nd Approver Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(391)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ393.toString()}
          label="Part Release 2 Funder Approval "
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(393)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ395.toString()}
          label="Part Release 3 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(395)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(395)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ397.toString()} label="Part Release 3 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(397)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ399.toString()}
          label="Part Release 3 2nd Approver Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(399)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ401.toString()}
          label="Part Release 3 Funder Approval"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(401)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ403.toString()}
          label="Part Release 4 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(403)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(403)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ405.toString()} label="Part Release 4 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(405)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ407.toString()}
          label="Part Release 4 2nd Approver Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(407)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ409.toString()}
          label="Part Release 4 Funder Approval"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(409)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ411.toString()}
          label="Part Release 5 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(411)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(411)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ413.toString()} label="Part Release 5 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(413)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ415.toString()}
          label="Part Release 5 2nd Approver Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(415)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ417.toString()}
          label="Part Release 5 Funder Approval"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(417)}
          setValue={handleState}
        />
         <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ419.toString()}
          label="Part Release 6 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(419)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(419)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ421.toString()} label="Part Release 6 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(421)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ423.toString()}
          label="Part Release 6 2nd Approver Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(423)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ425.toString()}
          label="Part Release 6 Funder Approval"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(425)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ427.toString()}
          label="Add or Remove Party Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(427)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(427)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ429.toString()}
          label="Add or Remove Party Decision Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(429)}
          setValue={handleState}
        />
                <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ430.toString()} label="Spare 1" value={currentCaseAssessorQuestions.GetQuestionAnswer(430)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ432.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(432)} setValue={handleState} />
        </div>


        <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ285.toString()}
          label="HOT decision"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(285)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(285)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ286.toString()}
          label="Bespoke Pricing?"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(286)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(286)}
        />
         <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ288.toString()}
          label="SAN decision"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(288)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(288)}
        />
       <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ290.toString()} label="SAN date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(290)} setValue={handleState} />
       <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ292.toString()}
          label="SAN 2nd Approver decision"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(292)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(292)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ294.toString()} label="SAN 2nd Approver date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(294)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ296.toString()}
          label="SAN Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(296)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ298.toString()}
          label="COM decision"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(298)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(298)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ300.toString()} label="COM decision date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(300)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ302.toString()} label="D1 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(302)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ304.toString()}
          label="D2 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(304)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(304)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ306.toString()} label="D2 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(306)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ308.toString()} label="D3 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(308)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ310.toString()}
          label="D4 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(310)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(310)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ312.toString()} label="D4 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(312)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ314.toString()} label="D5 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(314)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ316.toString()}
          label="D6 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(316)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(316)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ318.toString()} label="D6 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(318)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ320.toString()} label="D7 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(320)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ322.toString()}
          label="D8 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(322)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(322)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ324.toString()} label="D8 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(324)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ326.toString()} label="D9 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(326)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ328.toString()}
          label="D10 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(328)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(328)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ330.toString()} label="D10 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(330)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ332.toString()} label="D11 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(332)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ334.toString()}
          label="D12 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(334)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(334)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ336.toString()} label="D12 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(336)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ338.toString()} label="D13 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(338)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ340.toString()}
          label="D14 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(340)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(340)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ342.toString()} label="D14 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(342)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ344.toString()} label="D15 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(344)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ346.toString()}
          label="S106 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(346)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(346)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ348.toString()} label="S106 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(348)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ350.toString()} label="DOE 1 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(350)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ352.toString()}
          label="DOE 2 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(352)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(352)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ354.toString()} label="DOE 2 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(354)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ356.toString()} label="DOP Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(356)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ358.toString()}
          label="Consent 1 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(358)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(358)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ360.toString()} label="Consent 1 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(360)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ362.toString()} label="Consent 2 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(362)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ364.toString()}
          label="Consent 3 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(364)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(364)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ366.toString()} label="Consent 3 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(366)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ368.toString()} label="Consent 4 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(368)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ370.toString()}
          label="Consent 5 Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(370)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(370)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ372.toString()} label="Consent 5 Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(372)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ374.toString()} label="Consent 6 Decision Summary" value={currentCaseAssessorQuestions.GetQuestionAnswer(374)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ376.toString()}
          label="2nd Charge Name of Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(376)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(376)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ378.toString()} label="2nd Charge Decision Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(378)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ380.toString()}
          label="Part Release 1 Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(380)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ382.toString()}
          label="Part Release 1 Name of 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(382)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(382)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ384.toString()}
          label="Part Release 1 2nd Approver Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(384)}
          setValue={handleState}
        />

        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ386.toString()}
          label="Part Release 1 Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(386)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ388.toString()}
          label="Part Release 2 Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(388)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ390.toString()}
          label="Part Release 2 Name of 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(390)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(390)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ392.toString()}
          label="Part Release 2 2nd Approver Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(392)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ394.toString()}
          label="Part Release 2 Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(394)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ396.toString()}
          label="Part Release 3 Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(396)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ398.toString()}
          label="Part Release 3 Name of 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(398)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(398)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ400.toString()}
          label="Part Release 3 2nd Approver Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(400)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ402.toString()}
          label="Part Release 3 Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(402)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ404.toString()}
          label="Part Release 4 Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(404)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ406.toString()}
          label="Part Release 4 Name of 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(406)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(406)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ408.toString()}
          label="Part Release 4 2nd Approver Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(408)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ410.toString()}
          label="Part Release 4 Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(410)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ412.toString()}
          label="Part Release 5 Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(412)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ414.toString()}
          label="Part Release 5 Name of 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(414)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(414)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ416.toString()}
          label="Part Release 5 2nd Approver Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(416)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ418.toString()}
          label="Part Release 5 Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(418)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ420.toString()}
          label="Part Release 6 Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(420)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ422.toString()}
          label="Part Release 6 Name of 2nd Approver"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(422)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(422)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ424.toString()}
          label="Part Release 6 2nd Approver Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(424)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ426.toString()}
          label="Part Release 6 Funder Comments/Conditions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(426)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ428.toString()}
          label="Add or Remove Party Decision Summary"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(428)}
          setValue={handleState}
        />
                <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ431.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(431)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ433.toString()} label="Spare 4" value={currentCaseAssessorQuestions.GetQuestionAnswer(433)} setValue={handleState} />
      </div>
    </div>
  );
}
