import moment from "moment";

export default class ApplicationProgressDates {
    public PaymentDate: string = '';
    public MortOfferSent: string = '';

    constructor(PaymentDate: string, MortOfferSent: string) {
        const parsedDate = moment(PaymentDate, 'DD-MM-YYYY');
        if (parsedDate.isValid()) {
            this.PaymentDate = PaymentDate;
        }
        this.MortOfferSent = MortOfferSent;
    }

    getPaymentDate(): Date | null {
        const parsedDate = moment(this.PaymentDate, 'DD-MM-YYYY');
        if (parsedDate.isValid()) {
            return parsedDate.toDate();
        }
        return null;
    }
  }