export class MultiContact {
    public Firstname: string;
    public Surname: string | null;
    public CustomFields: Array<{
        CustomFieldNo: string,
        CustomFieldValue: string
    }>;

    constructor(Firstname: string, Surname: string | null, CustomFields: Array<{
        CustomFieldNo: string,
        CustomFieldValue: string
    }> = []) {
        this.Firstname = Firstname;
        this.Surname = Surname;
        this.CustomFields = CustomFields;
    }
}