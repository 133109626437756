import React, {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";


export default function Insurance() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

        case ESummaryFieldTypes.CaseAssessorQ181:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(181, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ182:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(182, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ183:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(183, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ184:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(184, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ185:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(185, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ186:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(186, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ187:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(187, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ188:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(188, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ189:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(189, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ190:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(190, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ191:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(191, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ192:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(192, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ193:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(193, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ194:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(194, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ195:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(195, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ196:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(196, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ197:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(197, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ198:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(198, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ199:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(199, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ200:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(200, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ201:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(201, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ202:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(202, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ203:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(203, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ204:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(204, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ205:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(205, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ206:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(206, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ207:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(207, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ208:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(208, value);
          break;
    
        case ESummaryFieldTypes.CaseAssessorQ209:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(209, value);
          break;

    }
    setCase(currentCase);
    updateTabsModified("Insurance", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ181.toString()} label="Insurer" value={currentCaseAssessorQuestions.GetQuestionAnswer(181)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ183.toString()}  label="Policy Number" value={currentCaseAssessorQuestions.GetQuestionAnswer(183)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ185.toString()} label="Co-Insured" value={currentCaseAssessorQuestions.GetQuestionAnswer(185)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(185)} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ187.toString()} label="1-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(187)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ189.toString()} label="Day after expiry reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(189)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ191.toString()} label="Contractors Insurance - Policy Holder" value={currentCaseAssessorQuestions.GetQuestionAnswer(191)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ193.toString()} label="Contractors Insurance - Amount insured" value={currentCaseAssessorQuestions.GetQuestionAnswer(193)} setValue={handleState} />
        {/*
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ193.toString()} label="Contractors Insurance - Amount insured" value={currentCaseAssessorQuestions.GetQuestionAnswer(193)} setValue={handleState} />
        */}
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ195.toString()} label="Contractors Insurance - Expiry date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(195)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ197.toString()} label="Contractors Insurance - 2-week reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(197)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ199.toString()} label="Contractor PEL Insurer" value={currentCaseAssessorQuestions.GetQuestionAnswer(199)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ201.toString()} label="Contractor PEL Policy Number" value={currentCaseAssessorQuestions.GetQuestionAnswer(201)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ203.toString()} label="Contractor PEL Expiry date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(203)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ205.toString()} label="Contractor PEL 2-week reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(205)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ184.toString()} label="Reinstatement Value" value={currentCaseAssessorQuestions.GetQuestionAnswer(184)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ209.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(209)}  setValue={handleState} />
        </div>
        <div className="form-column">
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ182.toString()} label="Policy Holder" value={currentCaseAssessorQuestions.GetQuestionAnswer(182)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ184.toString()} label="Amount insured" value={currentCaseAssessorQuestions.GetQuestionAnswer(184)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ186.toString()} label="Expiry date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(186)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ188.toString()} label="2-week reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(188)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ190.toString()} label="Contractors Insurance - Insurer" value={currentCaseAssessorQuestions.GetQuestionAnswer(190)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ192.toString()} label="Contractors Insurance - Policy Number" value={currentCaseAssessorQuestions.GetQuestionAnswer(192)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ194.toString()} label="Contractors Insurance - Co-Insured" value={currentCaseAssessorQuestions.GetQuestionAnswer(194)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(194)} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ196.toString()} label="Contractors Insurance - 1-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(196)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ198.toString()} label="Contractors Insurance - Day after expiry reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(198)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ200.toString()} label="Contractor PEL Policy Holder" value={currentCaseAssessorQuestions.GetQuestionAnswer(200)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ202.toString()} label="Contractor PEL Amount insured" value={currentCaseAssessorQuestions.GetQuestionAnswer(202)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ204.toString()} label="Contractor PEL 1-month reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(204)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ206.toString()} label="Contractor PEL Day after expiry reminder" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(206)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ208.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(208)} setValue={handleState} />
      </div>
    </div>
  );
}