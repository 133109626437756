import { createContext } from 'react';
import Case from '../../models/Case/UpdateCase/Case';
import CaseAssessorQuestions from '../../models/CaseAssessor/GetCaseAssessor/CaseAssessorQuestions';

export const UpdateCaseContext = createContext({
    currentCase: new Case(null, null),
    setCase: ( newCase: Case) => {  },
    customerReference: null,
    currentCaseAssessorQuestions: new CaseAssessorQuestions([]),
    setCaseAssessorQuestions: (newCaseAssessor: CaseAssessorQuestions) => {  },
    saveSuccess: false,
    setSaveSuccess: (value: boolean) => {},
    updateTabsModified: (category: string, isModified: boolean) => {},
    unsavedChanges: false,
    setUnsavedChanges: (value: boolean) => {},
  });