import React from 'react';
import {useContext, useEffect, useState} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormReadonly from "../Form/FormReadonlyInput";
import FormReadonlyWithABadge from "../Form/FormReadonlyWithABadge";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function Underwriting() {
  const {currentCase, setCase, customerReference, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  const [borrowingName, setBorrowingName] = useState<string[]>([]);
  const [directorsShareholders, setDirectorsShareholders] = useState('');
  const [surveyRequestedDate, setSurveyRequestedDate] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');

  useEffect(() => {

      if (currentCase?.CaseApp) {
        const name = currentCase.CaseApp.getBorrowingName();
        setBorrowingName(name);

        let directorsAndShareholders = currentCase.CaseApp.getDirectorsShareholdersCompanyNumber();

        const processedDirectorsAndShareholders = directorsAndShareholders.split(', ').map(entry => {
            const parts = entry.split(' ');
            return parts.slice(0, parts.length - 1).join(' ');
        }).join(', ');

        setDirectorsShareholders(processedDirectorsAndShareholders);

        const surveyDate = currentCase.CaseApp.getSurveyRequestedDate();
        setSurveyRequestedDate(surveyDate);

        const companyNumbers = directorsAndShareholders.split(', ').map(entry => {
            const parts = entry.split(' ');
            return parts.length > 1 ? parts[parts.length - 1] : 'n/a';
        });

        setCompanyNumber(companyNumbers.join(', '));
    }
  }, [currentCase?.CaseApp]);


  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.DynamicDataField2:
        currentCase.UpdateDynamicDataField(2, value);
        break;
      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

      case ESummaryFieldTypes.DynamicDataField5:
        currentCase.UpdateDynamicDataField(5, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ1:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(1, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ62:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(62, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ63:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(63, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ64:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(64, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ65:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(65, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ66:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(66, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ67:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(67, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ68:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(68, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ69:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(69, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ70:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(70, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ71:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(71, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ72:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(72, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ73:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(73, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ74:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(74, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ75:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(75, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ76:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(76, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ77:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(77, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ78:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(78, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ79:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(79, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ80:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(80, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ81:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(81, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ82:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(82, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ83:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(83, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ84:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(84, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ85:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(85, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ86:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(86, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ87:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(87, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ88:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(88, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ89:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(89, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ90:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(90, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ91:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(91, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ92:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(92, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ93:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(93, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ94:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(94, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ95:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(95, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ96:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(96, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ97:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(97, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ98:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(98, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ99:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(99, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ100:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(100, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ101:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(101, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ102:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(102, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ103:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(103, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ104:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(104, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ105:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(105, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ106:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(106, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ107:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(107, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ108:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(108, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ109:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(109, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ110:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(110, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ111:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(111, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ112:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(112, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ113:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(113, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ114:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(114, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ115:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(115, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ116:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(116, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ117:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(117, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ118:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(118, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ119:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(119, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ120:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(120, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ121:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(121, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ122:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(122, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ123:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(123, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("Underwriting", hasValueChanged);
    setUnsavedChanges(hasValueChanged);
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ62.toString()} label="Deal Overview" value={currentCaseAssessorQuestions.GetQuestionAnswer(62)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ64.toString()} label="Country of Incorporation" value={currentCaseAssessorQuestions.GetQuestionAnswer(64)} setValue={handleState}  values={currentCaseAssessorQuestions.GetQuestionAnswerList(64)} />
        <FormReadonly fieldName="CustomerReference" label="Customer Reference" value={customerReference || ""} />
        <FormReadonly fieldName="Directors/Shareholders - Company Number" label="Directors/Shareholders" value={directorsShareholders} displayMode="list"/>

        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ67.toString()} label="Personal Guarantee % Agreed" value={currentCaseAssessorQuestions.GetQuestionAnswer(67)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ69.toString()}
          label="Net Worth Relative to Gross Loan Score (%)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(69)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(69)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ71.toString()}
          label="Google Checks"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(71)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(71)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ73.toString()}
          label="Interview - Organisation Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(73)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(73)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ75.toString()}
          label="Interview - Attitude to Risk Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(75)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(75)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ77.toString()}
          label="Client Experience"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(77)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(77)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ79.toString()} label="Summary of Client Experience" value={currentCaseAssessorQuestions.GetQuestionAnswer(79)} setValue={handleState} />
        {/* <FormReadonly fieldName={"ValuationType"} label="Valuation Type" value={currentCase.CaseApp?.GetValuationDetails().ValuationType} /> */}
        <FormReadonly fieldName={""} label="Valuation Report Required" value={currentCase.CaseApp?.GetValuationDetails().ValuationType} displayMode="list" />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ81.toString()}
          label="Is this a below Market Value transaction"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(81)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(81)}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ83.toString()}
          label="No of Personal CCJ's - Satisifed"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(83)}
          setValue={handleState}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ85.toString()}
          label="No of Personal CCJ's - Unsatisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(85)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ87.toString()}
          label="Discharged Bankrupt or IVA"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(87)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(87)}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ89.toString()}
          label="No of Business CCJ's - Satisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(89)}
          setValue={handleState}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ91.toString()}
          label="No of Business CCJ's - Unsatisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(91)}
          setValue={handleState}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ93.toString()} label="Key Borrower Financials" value={currentCaseAssessorQuestions.GetQuestionAnswer(93)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ95.toString()}
          label="Reliance on Debt Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(95)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(95)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ96.toString()} label="Exit Strategy Review" value={currentCaseAssessorQuestions.GetQuestionAnswer(96)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ98.toString()} label="Deposit" value={currentCaseAssessorQuestions.GetQuestionAnswer(98)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ100.toString()} label="Deposit Source Details" value={currentCaseAssessorQuestions.GetQuestionAnswer(100)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ102.toString()}
          label="Asset Comparables Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(102)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(102)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ104.toString()}
          label="Valuation LTGDV worked off"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(104)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(104)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ106.toString()}
          label="Project Complexity Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(106)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(106)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ108.toString()}
          label="Project Diligence Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(108)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(108)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ110.toString()} label="HOT Strengths" value={currentCaseAssessorQuestions.GetQuestionAnswer(110)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ112.toString()} label="SAN Strengths" value={currentCaseAssessorQuestions.GetQuestionAnswer(112)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ113.toString()} label="SAN Weaknesses & Mitigants" value={currentCaseAssessorQuestions.GetQuestionAnswer(113)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ115.toString()} label="AML/KYC Assessment Type" value={currentCaseAssessorQuestions.GetQuestionAnswer(115)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(115)} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ117.toString()} label="Gross LTTC" value={currentCaseAssessorQuestions.GetQuestionAnswer(117)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ119.toString()} label="Details of Google information found" value={currentCaseAssessorQuestions.GetQuestionAnswer(119)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ121.toString()} label="Occupation Type" value={currentCaseAssessorQuestions.GetQuestionAnswer(121)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(121)} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ123.toString()} label="Guarantors" value={currentCaseAssessorQuestions.GetQuestionAnswer(123)} setValue={handleState} />
        </div>
        <div className="form-column">
        <FormReadonly fieldName="Borrowing Name" label="Borrowing Name" value={borrowingName} displayMode="list" />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ63.toString()} label="SIC Code" value={currentCaseAssessorQuestions.GetQuestionAnswer(63)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ65.toString()}
          label="Entity"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(65)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(65)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ66.toString()} label="Ultimate Beneficial Owners" value={currentCaseAssessorQuestions.GetQuestionAnswer(66)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ68.toString()} label="Overall Net Worth" value={currentCaseAssessorQuestions.GetQuestionAnswer(68)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ70.toString()}
          label="Existing Customer Conduct Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(70)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(70)}
        />
          <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ72.toString()}
          label="Google Check Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(72)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(72)}
        />
         <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ74.toString()}
          label="Interview - Technical Knowledge Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(74)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(74)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ76.toString()} label="Client Background" value={currentCaseAssessorQuestions.GetQuestionAnswer(76)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ78.toString()}
          label="Client Experience Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(78)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(78)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ80.toString()}
          label="Where Refinance, what loan are we repaying"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(80)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(80)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.DynamicDataField5.toString()}
          label="Title Insurance"
          value={currentCase.GetDynamicDataField(5)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(5)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ82.toString()} label="Personal Credit" value={currentCaseAssessorQuestions.GetQuestionAnswer(82)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ84.toString()}
          label="£ of Personal CCJ's - Satisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(84)}
          setValue={handleState}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ86.toString()}
          label="£ of Personal CCJ's - Unsatisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(86)}
          setValue={handleState}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ88.toString()} label="Business Credit" value={currentCaseAssessorQuestions.GetQuestionAnswer(88)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ90.toString()}
          label="£ of Business CCJ's - Satisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(90)}
          setValue={handleState}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ92.toString()}
          label="£ of Business CCJ's - Unsatisfied"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(92)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ94.toString()}
          label="Financial Performance Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(94)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(94)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.DynamicDataField2.toString()}
          label="Proposed Exit Strategy"
          value={currentCase.GetDynamicDataField(2)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(2)}
        />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ97.toString()} label="Exit Score" value={currentCaseAssessorQuestions.GetQuestionAnswer(97)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(97)} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ99.toString()}
          label="Deposit Source"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(99)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(99)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ101.toString()}
          label="Asset Liquidity Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(101)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(101)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ103.toString()}
          label="Valuation LTV worked off"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(103)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(103)}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ105.toString()}
          label="Specific Solicitor Instructions"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(105)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ107.toString()}
          label="Project Build Team Score"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(107)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(107)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ109.toString()}
          label="Exceptions to Lending Guidelines"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(109)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(109)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ111.toString()} label="HOT Weaknesses & Mitigants" value={currentCaseAssessorQuestions.GetQuestionAnswer(111)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ114.toString()} label="Day one LTV (Refurb/Dev only)" value={currentCaseAssessorQuestions.GetQuestionAnswer(114)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ116.toString()} label="Net LTTC" value={currentCaseAssessorQuestions.GetQuestionAnswer(116)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ118.toString()} label="Passed Funder Eligibility Criteria" value={currentCaseAssessorQuestions.GetQuestionAnswer(118)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(118)} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ120.toString()} label="Details of LG Exceptions" value={currentCaseAssessorQuestions.GetQuestionAnswer(120)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ122.toString()} label="Estimated Completion Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(122)} setValue={handleState} />
      </div>
    </div>
  );
}
