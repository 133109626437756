import React, {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function PostCompletion() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ210:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(210, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ211:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(211, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ212:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(212, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ213:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(213, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ214:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(214, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ215:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(215, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ216:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(216, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ217:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(217, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ218:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(218, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ219:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(219, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ220:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(220, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ221:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(221, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ222:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(222, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ223:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(223, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ224:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(224, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ225:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(225, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ226:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(226, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ227:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(227, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ228:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(228, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ229:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(229, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ230:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(230, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ231:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(231, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ232:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(232, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ233:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(233, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ234:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(234, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ235:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(235, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ236:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(236, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ237:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(237, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ238:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(238, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ239:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(239, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ240:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(240, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ241:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(241, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ242:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(242, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ243:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(243, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ244:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(244, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ245:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(245, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ246:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(246, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ247:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(247, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ248:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(248, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ249:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(249, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ250:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(250, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ251:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(251, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ252:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(252, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ253:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(253, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ254:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(254, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ255:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(255, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("PostCompletion", hasValueChanged); 
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ210.toString()} label="Last inspection date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(210)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ212.toString()}
          label="Customer Predicted Programme Completion Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(212)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ214.toString()}
          label="Latest Interim site visit date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(214)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ216.toString()} label="Expected drawdown date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(216)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ218.toString()}
          label="Latest Interim report received"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(218)}
          setValue={handleState}
        />
         <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ220.toString()}
          label="Number of forecast draws"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(220)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ222.toString()}
          label="MS programme completion date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(222)}
          setValue={handleState}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ224.toString()}
          label="MS confirmation % project complete"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(224)}
          setValue={handleState}
        />
         <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ226.toString()}
          label="Drawdown Stopped reason"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(226)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(226)}
        />
         <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ228.toString()}
          label="BridgeRFB Site Visit Instructed Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(228)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ230.toString()}
          label="BridgeRFB Interim report due back"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(230)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ232.toString()}
          label="BridgeRFB Further site visits required"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(232)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(232)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ234.toString()}
          label="BridgeRFB MS programme completion date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(234)}
          setValue={handleState}
        />
         <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ236.toString()}
          label="BridgeRFB MS confirmation of % completed"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(236)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ238.toString()}
          label="Re-inspection Valuation quotes requested"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(238)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ240.toString()}
          label="Re-inspection Valuation inspection date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(240)}
          setValue={handleState}
        />
         <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ242.toString()}
          label="Consent Request Received"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(242)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(242)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ244.toString()}
          label="Date LiveLoan Solicitors instructed"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(244)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ246.toString()}
          label="Fees due to process request"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(246)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(246)}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ248.toString()}
          label="Value of security being released"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(248)}
          setValue={handleState}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ250.toString()}
          label="Proceeds expected from release"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(250)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ252.toString()} label="Last 9Y Site Visit" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(252)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ254.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(254)} setValue={handleState} />
        </div>
        <div className="form-column">
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ211.toString()} label="Last drawdown date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(211)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ213.toString()}
          label="Drawdown fee to be charged"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(213)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(213)}
        />
         <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ215.toString()}
          label="Date Interim report due back"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(215)}
          setValue={handleState}
        />
         <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ217.toString()}
          label="Amount expected for drawdown"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(217)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ219.toString()}
          label="Date last drawdown funds released"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(219)}
          setValue={handleState}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ221.toString()}
          label="Number of draws taken so far"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(221)}
          setValue={handleState}
        />
         <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ223.toString()}
          label="Latest programme completion date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(223)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ225.toString()} label="Drawdown Stopped" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(225)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ227.toString()}
          label="Bridge Midway Site Visit NoRfb"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(227)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ229.toString()}
          label="BridgeRFB - Interim site visit date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(229)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ231.toString()}
          label="BridgeRFB Last inspection date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(231)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ233.toString()}
          label="BridgeRFB Customer programme completion date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(233)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ235.toString()}
          label="BridgeRFB Latest programme completion date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(235)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ237.toString()}
          label="BridgeRFB Actual programme completion date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(237)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ239.toString()}
          label="Re-inspection Valuation instructed"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(239)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ241.toString()}
          label="Re-inspection Valuation report due back date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(241)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ243.toString()}
          label="Name LiveLoan Solicitors instructed"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(243)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(243)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ245.toString()}
          label="Last contact from solicitor"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(245)}
          setValue={handleState}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ247.toString()}
          label="Addresses of Security Released"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(247)}
          setValue={handleState}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ249.toString()}
          label="Remaining Security Value"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(249)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ251.toString()} label="Date released" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(251)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ253.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(253)} setValue={handleState} />
      </div>
    </div>
  );
}
