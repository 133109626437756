import React, {useContext} from "react";
import FormSelect from "../Form/FormSelect";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormTextArea from "../Form/FormTextArea";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function Project() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.DynamicDataField2:
        currentCase.UpdateDynamicDataField(2, value);
        break;

      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ124:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(124, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ125:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(125, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ126:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(126, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ127:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(127, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ128:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(128, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ129:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(129, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ129:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(129, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ130:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(130, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ131:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(131, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ132:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(132, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ133:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(133, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ134:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(134, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ135:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(135, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ136:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(136, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ137:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(137, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ138:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(138, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ139:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(139, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ140:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(140, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ141:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(141, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ142:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(142, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ143:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(143, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ144:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(144, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ145:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(145, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ146:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(146, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ147:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(147, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ148:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(148, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ149:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(149, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ150:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(150, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ151:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(151, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ152:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(152, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ153:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(153, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ154:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(154, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ155:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(155, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ156:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(156, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ157:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(157, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ158:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(158, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ159:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(159, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ160:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(160, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ161:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(161, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ162:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(162, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ163:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(163, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ164:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(164, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ165:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(165, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ166:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(166, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ167:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(167, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ168:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(168, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ170:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(170, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ171:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(171, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ172:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(172, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ173:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(173, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ174:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(174, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ175:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(175, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ176:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(176, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ177:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(177, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ178:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(178, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ179:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(179, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ180:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(180, value);
        break;
      
        case ESummaryFieldTypes.CaseAssessorA169:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(169, value);
        break;

        case ESummaryFieldTypes.CaseAssessorA482:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(482, value);
        break;

        case ESummaryFieldTypes.CaseAssessorQ483:
          currentCaseAssessorQuestions.UpdateQuestionAnswer(483, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("Project", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ124.toString()}
          label="Date Works Must be Commenced by"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(124)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ126.toString()}
          label="Development Category"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(126)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(126)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ128.toString()}
          label="Predicted Project Complete Date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(128)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ129.toString()}
          label="Expected Finish Quality"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(129)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(129)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ131.toString()}
          label="Is planning permission in place"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(131)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(131)}
        />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ133.toString()}
          label="Site Visit Overview On Location"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(133)}
          setValue={handleState}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ135.toString()} label="Client Purchase Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(135)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ137.toString()} label="Client Contingency" value={currentCaseAssessorQuestions.GetQuestionAnswer(137)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ139.toString()}
          label="Client Statutory Costs (CIL/S106)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(139)}
          setValue={handleState}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ141.toString()}
          label="Client Total Drawdown Tranche"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(141)}
          setValue={handleState}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ483.toString()} label="MS Purchase Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(483)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ144.toString()} label="MS Contigency" value={currentCaseAssessorQuestions.GetQuestionAnswer(144)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ146.toString()}
          label="MS Statutory Costs (CIL/S106)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(146)}
          setValue={handleState}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ148.toString()} label="MS Total Drawdown Tranche" value={currentCaseAssessorQuestions.GetQuestionAnswer(148)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ150.toString()} label="Approved Purchase Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(150)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ152.toString()} label="Approved Contingency" value={currentCaseAssessorQuestions.GetQuestionAnswer(152)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ154.toString()} label="Approved Statutory Costs (CIL/S106)" value={currentCaseAssessorQuestions.GetQuestionAnswer(154)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ156.toString()} label="Approved Total Drawdown Tranche" value={currentCaseAssessorQuestions.GetQuestionAnswer(156)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ158.toString()} label="Profit on Cost %" value={currentCaseAssessorQuestions.GetQuestionAnswer(158)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ160.toString()} label="Client Return on Investment (Cash on Cash)" value={currentCaseAssessorQuestions.GetQuestionAnswer(160)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ162.toString()} label="Contractor Name" value={currentCaseAssessorQuestions.GetQuestionAnswer(162)} setValue={handleState} />
        <FormSelect fieldName={ESummaryFieldTypes.CaseAssessorQ164.toString()} label="Type of Contract in Place" value={currentCaseAssessorQuestions.GetQuestionAnswer(164)} setValue={handleState} values={currentCaseAssessorQuestions.GetQuestionAnswerList(164)} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ166.toString()} label="Architect Company" value={currentCaseAssessorQuestions.GetQuestionAnswer(166)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ168.toString()}  label="Architect Email" value={currentCaseAssessorQuestions.GetQuestionAnswer(168)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorA169.toString()} label="Structural Enginneer Name" value={currentCaseAssessorQuestions.GetQuestionAnswer(169)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ171.toString()} label="Article 4 Area" value={currentCaseAssessorQuestions.GetQuestionAnswer(171)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ173.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(173)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ175.toString()} label="Spare 5" value={currentCaseAssessorQuestions.GetQuestionAnswer(175)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ177.toString()} label="Spare 7" value={currentCaseAssessorQuestions.GetQuestionAnswer(177)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ179.toString()} label="Spare 9" value={currentCaseAssessorQuestions.GetQuestionAnswer(179)} setValue={handleState} />
        </div>
        <div className="form-column">
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ125.toString()}
          label="Date 1st Drawdown Must Be Taken By"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(125)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ127.toString()}
          label="Development Stage"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(127)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(127)}
        />
        {/* GetDynamicQuestionAnswerList */}
        <FormSelect fieldName={ESummaryFieldTypes.DynamicDataField3.toString()} label="Monitoring Surveyor" value={currentCase.GetDynamicDataField(3)} setValue={handleState} values={currentCaseAssessorQuestions.GetDynamicQuestionAnswerList(3)} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ130.toString()} label="Sq ft (To Be)" value={currentCaseAssessorQuestions.GetQuestionAnswer(130)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ132.toString()} label="Planning link" value={currentCaseAssessorQuestions.GetQuestionAnswer(132)} setValue={handleState} />
        <FormTextArea
          fieldName={ESummaryFieldTypes.CaseAssessorQ134.toString()}
          label="Site Visit Overview on Project Mobilisation"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(134)}
          setValue={handleState}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ136.toString()} label="Client Build Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(136)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ138.toString()}
          label="Client Total Professional Fees (Surveys, Planning, Consultants & Insurances & Warranties)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(138)}
          setValue={handleState}
        />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ140.toString()}
          label="Client Sales & Marketing Costs"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(140)}
          setValue={handleState}
        />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ142.toString()} label="Client Build Timescale" value={currentCaseAssessorQuestions.GetQuestionAnswer(142)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ143.toString()} label="MS Build Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(143)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ145.toString()}
          label="MS Total Professional Fees (Surveys, Planning, Consultants & Insurances & Warranties)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(145)}
          setValue={handleState}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ147.toString()} label="MS Sales & Marketing Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(147)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ149.toString()} label="MS Build Timescale" value={currentCaseAssessorQuestions.GetQuestionAnswer(149)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ151.toString()} label="Approved Build Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(151)} setValue={handleState} />
        <FormCurrencyInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ153.toString()}
          label="Approved Total Professional Fees (Surveys, Planning, Consultants & Insurances & Warranties)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(153)}
          setValue={handleState}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ155.toString()} label="Approved Sales & Marketing Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(155)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ157.toString()} label="Approved Build Timescale" value={currentCaseAssessorQuestions.GetQuestionAnswer(157)}  setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ159.toString()} label="Profit on GDV%" value={currentCaseAssessorQuestions.GetQuestionAnswer(159)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ161.toString()} label="Contractor Company" value={currentCaseAssessorQuestions.GetQuestionAnswer(161)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ163.toString()} label="Contractor Email" value={currentCaseAssessorQuestions.GetQuestionAnswer(163)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ165.toString()} label="Contractor Administrator" value={currentCaseAssessorQuestions.GetQuestionAnswer(165)} setValue={handleState} />
        <FormTextArea  fieldName={ESummaryFieldTypes.CaseAssessorQ167.toString()} label="Architect Name" value={currentCaseAssessorQuestions.GetQuestionAnswer(167)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorA482.toString()} label="Structural Enginneer Email" value={currentCaseAssessorQuestions.GetQuestionAnswer(482)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ170.toString()} label="Building Control" value={currentCaseAssessorQuestions.GetQuestionAnswer(170)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ172.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(172)}setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ174.toString()} label="Spare 4" value={currentCaseAssessorQuestions.GetQuestionAnswer(174)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ176.toString()} label="Spare 6" value={currentCaseAssessorQuestions.GetQuestionAnswer(176)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ178.toString()} label="Spare 8" value={currentCaseAssessorQuestions.GetQuestionAnswer(178)} setValue={handleState} />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ180.toString()} label="Spare 10" value={currentCaseAssessorQuestions.GetQuestionAnswer(180)} setValue={handleState} />
      </div>
    </div>
  );
}
