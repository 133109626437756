import React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "../styles/KnowYourCustomer.css";
import Finance from "../components/KnowYourCustomer/Finance";
import Underwriting from "../components/KnowYourCustomer/Underwriting";
import PropertySecurity from "../components/KnowYourCustomer/PropertySecurity";
import Project from "../components/KnowYourCustomer/Project";
import Insurance from "../components/KnowYourCustomer/Insurance";
import PostCompletion from "../components/KnowYourCustomer/PostCompletion";
import ExitRepayment from "../components/KnowYourCustomer/ExitRepayment";
import SignoffApprovals from "../components/KnowYourCustomer/SignoffApprovals";
import Funder from "../components/KnowYourCustomer/Funder";
import Arrears from "../components/KnowYourCustomer/Arrears";
import CasesBreadcrumbs from "../components/CasesBreadcrumbs";
import CasesTopBar from "../components/CasesTopBar";
import arowsRightIcon from "../images/arrows-right.svg";
import ActionButtons from "../components/Form/ActionButtons";
import {UpdateCaseContext} from "../infrastructure/Context/UpdateCaseContext";
import Summary from "../components/KnowYourCustomer/Summary";
import Cookies from "js-cookie";
import UserInfo from "../models/User/UserInfo";
import GetCaseRequest from "../models/Case/GetCase/GetCaseRequest";
import GetCaseAssessorRequest from "../models/CaseAssessor/GetCaseAssessor/GetCaseAssessorRequest";
import GetDynamicDataByCase from "../models/CaseAssessor/GetCaseAssessor/GetDynamicDataByCase";
import CaseAssessorQuestions from "../models/CaseAssessor/GetCaseAssessor/CaseAssessorQuestions";
import Case from "../models/Case/UpdateCase/Case";
import CaseApp from "../models/Case/CaseApp";
import ApplicationProgressDates from "../models/Case/GetCase/ApplicationProgressDates";
import { PaymentPlanDetail } from "../models/Case/PaymentPlanDetail";
import { Applicant } from "../models/Case/Applicant";
import { MultiContact } from "../models/Case/MultiContact";
import { ValuationDetails } from "../models/Case/ValuationDetails";
import DynamicFieldDetail from "../models/Case/DynamicFieldDetail";
import SecurityAddress from "../models/Case/GetCase/SecurityAddress";
import SecurityCustomField from "../models/Case/GetCase/SecurityCustomField";
import { AdditionalFee } from "../models/Case/AdditionalFee";

enum ECategory {
  Summary = 1,
  FinancialInformation,
  Underwriting,
  PropertySecurity,
  Project,
  Insurance,
  PostCompletion,
  ExitRepayment,
  SignoffApprovals,
  Funder,
  Arrears,
}

const KnowYourCustomer = () => {
  const [currentCase, setCaseState] = useState<Case>(new Case(null, new CaseApp("", [], [], [], new ApplicationProgressDates('', ''), '', new PaymentPlanDetail(0))));
  const [currentCaseAssessorQuestions, setCaseAssessorState] = useState<CaseAssessorQuestions>(new CaseAssessorQuestions([]));
  const [customerReference, setCustomerReference] = useState(null);
  const [dynamicData, setDynamicData] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [tabsModified, setTabsModified] = useState({
    Summary: false,
    FinancialInformation: false,
    Underwriting: false,
    PropertySecurity: false,
    Project: false,
    Insurance: false,
    PostCompletion: false,
    ExitRepayment: false,
    SignoffApprovals: false,
    Funder: false,
    Arrears: false,
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  
  useEffect(() => {
    if (saveSuccess) {
      setTabsModified({
        Summary: false,
        FinancialInformation: false,
        Underwriting: false,
        PropertySecurity: false,
        Project: false,
        Insurance: false,
        PostCompletion: false,
        ExitRepayment: false,
        SignoffApprovals: false,
        Funder: false,
        Arrears: false,
      });
    }
  }, [saveSuccess]);
  const updateTabsModified = (category : any, isModified : any) => {
    setTabsModified(prev => ({ ...prev, [category]: isModified }));
  };

  const [active, setActive] = useState("Summary");

  let {id} = useParams();

  var credentials = Cookies.get("Credentials")?.split("|");
  if (!credentials) credentials = [];
  var userInfo: UserInfo = new UserInfo(credentials[0], credentials[1]);

  var getCaseRequest: GetCaseRequest = {
    UserInfo: userInfo,
    CaseReference: id != null ? id : "",
  };

  var getCaseAssessorRequest: GetCaseAssessorRequest = {
    UserInfo: userInfo,
    jobCode: id != null ? id : "",
  };

  var getDynamicDataByCase : GetDynamicDataByCase = {
      UserInfo: userInfo,
      CaseReference: id != null ? id : "",
  }

  const getCaseData = async () => {
    const request: RequestInit = {
      method: "POST",
      body: JSON.stringify(getCaseRequest),
      headers: {
        "Content-Type": "application/json",
      },
    };

  const response = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/GetCase", request).then((response) => response.json());

  // GetCustomer request
  const getCustomerRequest: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      UserInfo: userInfo,
      CustomerReference: response.d.CaseApplicant.CustomerReference
    }),
    headers: {
      "Content-Type": "application/json",
      },
    };
  const responseCustomer = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/GetCustomer ", getCustomerRequest).then((response) => response.json());
  setCustomerReference(responseCustomer.d.Customer.CustomerReference);

  var newCase: Case = new Case(null, new CaseApp(
  response.d.CaseApplicant.ApplicantID, 
  response.d.CaseApplicant.DynamicFieldDetail && response.d.CaseApplicant.DynamicFieldDetail.length > 0
  ? response.d.CaseApplicant.DynamicFieldDetail.map((dynamicField: any) => 
    new DynamicFieldDetail(dynamicField.FieldNo, dynamicField.FieldValue))
  : [],
  response.d.CaseApplicant.SecurityAddress && response.d.CaseApplicant.SecurityAddress.length > 0
  ? response.d.CaseApplicant.SecurityAddress.map((securityAddress: any) => 
    new SecurityAddress(securityAddress.Code, securityAddress.HouseName, securityAddress.HouseNumber, securityAddress.Address1, securityAddress.Address2, securityAddress.Address3, securityAddress.Address4,
          securityAddress.Postcode, securityAddress.SecuirtyCharge, securityAddress.SecuirtyStatus, securityAddress.SecuirtyPurchaseValue, securityAddress.SecuirtyPurchaseDate,
          securityAddress.SecuirtyMarketValue, securityAddress.SecuirtyMortgageValue, 
          securityAddress.SecurityCustomField.map((customField: any) => new SecurityCustomField(customField.CustomFieldNo, customField.CustomFieldValue)),
          securityAddress.SecurityDV90Days, securityAddress.SecurityGDV90Days, securityAddress.SecurityDV180Days, securityAddress.SecurityGDV180Days, 
          securityAddress.SecurityGDV, securityAddress.SecuirtyType, securityAddress.SecuritySubType, securityAddress.SecuirtyMarketValuePerc))
  : [], 
  response.d.CaseApplicant.ValuationDetails && response.d.CaseApplicant.ValuationDetails.length > 0
  ? response.d.CaseApplicant.ValuationDetails.map((valuation: any) =>
    new ValuationDetails(valuation.SurveyRequested, valuation.Surveyor, valuation.ValuationType))
  : [],
  new ApplicationProgressDates(response.d.CaseApplicant.ApplicationProgressDates?.PaymentDate || '', response.d.CaseApplicant.ApplicationProgressDates?.MortOfferSent),
  response.d.CaseApplicant.CustomerInitialFee,
  new PaymentPlanDetail(response.d.CaseApplicant.PaymentPlanDetail?.TotalLoan || ''),
  response.d.CaseApplicant.CustomerCompletionFee, 
  response.d.CaseApplicant.RolledUpInterestForTerm,
  response.d.CaseApplicant.BrokerAdminFee,
  response.d.CaseApplicant.ValuationFee,
  response.d.CaseApplicant.LegalFee,
  response.d.CaseApplicant.Deposit,
  response.d.CaseApplicant.OtherFee,
  response.d.CaseApplicant.TotalRepaymentAmount,
  response.d.CaseApplicant.AdditionalFee && response.d.CaseApplicant.AdditionalFee.length > 0
  ? response.d.CaseApplicant.AdditionalFee.map((additionalFee: any) => 
    new AdditionalFee(additionalFee.FeeNumber, additionalFee.Fee))
  : [],
  response.d.CaseApplicant.LastLoanInterestRaisedAmount,
  response.d.CaseApplicant.TotalLoanInterestRaisedAmount,
  response.d.CaseApplicant.InterestDaily ?? '',
  response.d.CaseApplicant.InterestMonthly ?? '',
  response.d.CaseApplicant.InterestYearly ?? '',
  new Applicant(response.d.CaseApplicant.MainApplicant.Title, response.d.CaseApplicant.MainApplicant.Firstname, response.d.CaseApplicant.MainApplicant.Surname) || "",
  response.d.CaseApplicant.AdditionalApplicants && response.d.CaseApplicant.AdditionalApplicants.length > 0
  ? response.d.CaseApplicant.AdditionalApplicants.map((applicant: any) => 
    new Applicant(applicant.Title, applicant.Firstname, applicant.Surname, applicant.App2ID))
  : [],
  response.d.CaseApplicant.MultiContacts && response.d.CaseApplicant.MultiContacts.length > 0
  ? response.d.CaseApplicant.MultiContacts.map((contact: any) => 
    new MultiContact(contact.Firstname, contact.Surname, contact.CustomFields))
  : [], response.d.CaseApplicant.LGDVPer,
  response.d.CaseApplicant.ExpectedLTV,
  response.d.CaseApplicant.NetClaim,
  response.d.CaseApplicant.OriginalLoanAmount
  ));

    if (newCase.CaseApp) {
      var caseApp = new CaseApp(
        response.d.CaseApplicant.ApplicantID,
        response.d.CaseApplicant.DynamicFieldDetail && response.d.CaseApplicant.DynamicFieldDetail.length > 0
        ? response.d.CaseApplicant.DynamicFieldDetail.map((dynamicField: any) => 
          new DynamicFieldDetail(dynamicField.FieldNo, dynamicField.FieldValue))
        : [],
        response.d.CaseApplicant.SecurityAddress && response.d.CaseApplicant.SecurityAddress.length > 0
        ? response.d.CaseApplicant.SecurityAddress.map((securityAddress: any) => 
          new SecurityAddress(securityAddress.Code, securityAddress.HouseName, securityAddress.HouseNumber, securityAddress.Address1, securityAddress.Address2, securityAddress.Address3, 
                securityAddress.Address4, securityAddress.Postcode, securityAddress.SecuirtyCharge, securityAddress.SecurityStatus, securityAddress.SecuirtyPurchaseValue,
                securityAddress.SecuirtyPurchaseDate, securityAddress.SecuirtyMarketValue, securityAddress.SecuirtyMortgageValue, 
                securityAddress.SecurityCustomField.map((customField: any) => new SecurityCustomField(customField.CustomFieldNo, customField.CustomFieldValue)),
                securityAddress.SecurityDV90Days, securityAddress.SecurityGDV90Days, securityAddress.SecurityDV180Days, securityAddress.SecurityGDV180Days, 
                securityAddress.SecurityGDV, securityAddress.SecuirtyType, securityAddress.SecuritySubType, securityAddress.SecuirtyMarketValuePerc))
        : [], 
        response.d.CaseApplicant.ValuationDetails && response.d.CaseApplicant.ValuationDetails.length > 0
        ? response.d.CaseApplicant.ValuationDetails.map((valuation: any) =>
          new ValuationDetails(valuation.SurveyRequested, valuation.Surveyor, valuation.ValuationType))
        : [],
        new ApplicationProgressDates(response.d.CaseApplicant.ApplicationProgressDates?.PaymentDate || '', response.d.CaseApplicant.ApplicationProgressDates?.MortOfferSent),
        response.d.CaseApplicant.CustomerInitialFee,
        new PaymentPlanDetail(response.d.CaseApplicant.PaymentPlanDetail.TotalLoan),
        response.d.CaseApplicant.CustomerCompletionFee, 
        response.d.CaseApplicant.RolledUpInterestForTerm,
        response.d.CaseApplicant.BrokerAdminFee,
        response.d.CaseApplicant.ValuationFee,
        response.d.CaseApplicant.LegalFee,
        response.d.CaseApplicant.Deposit,
        response.d.CaseApplicant.OtherFee,
        response.d.CaseApplicant.TotalRepaymentAmount,
        response.d.CaseApplicant.AdditionalFee && response.d.CaseApplicant.AdditionalFee.length > 0
        ? response.d.CaseApplicant.AdditionalFee.map((additionalFee: any) => 
              new AdditionalFee(additionalFee.FeeNumber, additionalFee.Fee))
        : [],
        response.d.CaseApplicant.LastLoanInterestRaisedAmount ?? '',
        response.d.CaseApplicant.TotalLoanInterestRaisedAmount ?? '',
        response.d.CaseApplicant.InterestDaily ?? '',
        response.d.CaseApplicant.InterestMonthly ?? '',
        response.d.CaseApplicant.InterestYearly ?? '',
        new Applicant(response.d.CaseApplicant.MainApplicant.Title, response.d.CaseApplicant.MainApplicant.Firstname, response.d.CaseApplicant.MainApplicant.Surname) || "",
        response.d.CaseApplicant.AdditionalApplicants && response.d.CaseApplicant.AdditionalApplicants.length > 0
        ? response.d.CaseApplicant.AdditionalApplicants.map((applicant: any) => 
          new Applicant(applicant.Title, applicant.Firstname, applicant.Surname, applicant.App2ID))
        : [],
        response.d.CaseApplicant.MultiContacts && response.d.CaseApplicant.MultiContacts.length > 0
        ? response.d.CaseApplicant.MultiContacts.map((contact: any) => 
          new MultiContact(contact.Firstname, contact.Surname, contact.CustomFields))
        : [], response.d.CaseApplicant.LGDVPer,
        response.d.CaseApplicant.ExpectedLTV,
        response.d.CaseApplicant.NetClaim,
        response.d.CaseApplicant.OriginalLoanAmount
       );
      newCase.CaseApp = caseApp;
    }
    return newCase;
  };

  const getCaseAssessorByCaseJSON = async () => {
    const request1: RequestInit = {
      method: "POST",
      body: JSON.stringify(getCaseAssessorRequest),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response1 = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/GetCaseAssessorByCaseJSON", request1).then((response) => response.json());

    const request2: RequestInit = {
      method: "POST",
      body: JSON.stringify(getDynamicDataByCase),
      headers: {
          "Content-Type": "application/json",
      },
    };

    const response2 = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/GetDynamicDataByCase", request2).then((response) => response.json());

    var newCaseAssessor: CaseAssessorQuestions = new CaseAssessorQuestions(response1.d.Records, response2.d.Records);
    return newCaseAssessor;
  }

  const loadData = async () => {
    var newCaseAssessor = await getCaseAssessorByCaseJSON();
    var newCase = await getCaseData();
    setCase(newCase);
    setCaseAssessorState(newCaseAssessor);
  }

  useEffect(() => {
    loadData();
  }, []);

  const [category, setCategory] = useState(ECategory.Summary);

  function fetchDetails(category: ECategory) {
    setCategory(category);
  }

  function setCase(case1: Case) {
    setCaseState(case1);
  }

  function setCaseAssessorQuestions(case1: CaseAssessorQuestions) {
    setCaseAssessorState(case1);
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setActive(event.currentTarget.id);
  };

  return (
    <UpdateCaseContext.Provider value={{currentCase, setCase, customerReference, currentCaseAssessorQuestions, setCaseAssessorQuestions, saveSuccess,  setSaveSuccess,  updateTabsModified, unsavedChanges,  setUnsavedChanges }}>
      <CasesTopBar />
      <CasesBreadcrumbs pageName="KnowYourCustomer" />
      <div className="container-fluid customer-forms-wrapper">
        <div className="row">
          <div className="col left-panel">
            <div
              id="Summary"
              className={active === "Summary" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.Summary);
                handleButtonClick(e);
              }}
            >
              Summary
              {tabsModified.Summary && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Financial information"
              className={active === "Financial information" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.FinancialInformation);
                handleButtonClick(e);
              }}
            >
              Financial information
              {tabsModified.FinancialInformation &&<span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Underwriting"
              className={active === "Underwriting" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.Underwriting);
                handleButtonClick(e);
              }}
            >
              Underwriting
              {tabsModified.Underwriting && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Property / Security"
              className={active === "Property / Security" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.PropertySecurity);
                handleButtonClick(e);
              }}
            >
              Property / Security
              {tabsModified.PropertySecurity && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Project"
              className={active === "Project" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.Project);
                handleButtonClick(e);
              }}
            >
              Project
              {tabsModified.Project && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Insurance"
              className={active === "Insurance" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.Insurance);
                handleButtonClick(e);
              }}
            >
              Insurance
              {tabsModified.Insurance && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Post Completion"
              className={active === "Post Completion" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.PostCompletion);
                handleButtonClick(e);
              }}
            >
              Post Completion
              {tabsModified.PostCompletion &&<span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Exit / Repayment"
              className={active === "Exit / Repayment" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.ExitRepayment);
                handleButtonClick(e);
              }}
            >
              Exit / Repayment
              {tabsModified.ExitRepayment && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Sign-off/Approvals"
              className={active === "Sign-off/Approvals" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.SignoffApprovals);
                handleButtonClick(e);
              }}
            >
              Sign-off/Approvals
              {tabsModified.SignoffApprovals && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Funder"
              className={active === "Funder" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.Funder);
                handleButtonClick(e);
              }}
            >
              Funder
              {tabsModified.Funder && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
            <div
              id="Arrears"
              className={active === "Arrears" ? "type-button active" : "type-button"}
              onClick={(e) => {
                fetchDetails(ECategory.Arrears);
                handleButtonClick(e);
              }}
            >
              Arrears
              {tabsModified.Arrears && <span className="edit-indicator-tab"></span>}
              <img className="arrow-icon" src={arowsRightIcon} alt="double arrow icon" />
            </div>
          </div>
          <div className="col-8 form-wrapper">
            <div className="form-header">
              <p>{active}</p>
            </div>
            {(() => {
              if (currentCase && currentCase.CaseApp?.ApplicantID) {
                switch (category) {
                  case ECategory.Summary:
                    return <Summary />;
                  case ECategory.FinancialInformation:
                    return <Finance id={id} title="dfsdf" />;
                  case ECategory.Underwriting:
                    return <Underwriting />;
                  case ECategory.PropertySecurity:
                    return <PropertySecurity />;
                  case ECategory.Project:
                    return <Project />;
                  case ECategory.Insurance:
                    return <Insurance />;
                  case ECategory.PostCompletion:
                    return <PostCompletion />;
                  case ECategory.ExitRepayment:
                    return <ExitRepayment />;
                  case ECategory.SignoffApprovals:
                    return <SignoffApprovals />;
                  case ECategory.Funder:
                    return <Funder />;
                  case ECategory.Arrears:
                    return <Arrears />;
                  default:
                    return null;
                }
              }
            })()}
          </div>
        </div>
      </div>
      <ActionButtons />
    </UpdateCaseContext.Provider>
  );
};

export default KnowYourCustomer;
