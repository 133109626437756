import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import CasesTopBar from "../components/CasesTopBar";
import CasesBreadcrumbs from "../components/CasesBreadcrumbs";
import Cookies from "js-cookie";
import moment from "moment";
import CasesResultRecord from "../models/SearchCases/CasesResultRecord";
import CasesResult from "../models/SearchCases/CasesResult";
import searchIcon from "../images/search.svg";
import xClose from "../images/x.svg";

function Cases() {
  const location = useLocation();

  const navigate = useNavigate();
  function fetchDetails(caseRecord: CasesResultRecord) {
    navigate("/knowyourcustomer/" + caseRecord.JobReference);
  }
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [gridData, setGridData] = useState<CasesResult | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("name");
  const [retrySearch, setRetrySearch] = useState(false);
  const pageSize = 50;
  const [searchCompleted, setSearchCompleted] = useState(false);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      const trimmedSearchValue = searchValue.trim().toUpperCase();
      if (trimmedSearchValue && !searchCompleted) {
        getApiData(pageIndex, searchValue.startsWith("CA") ? "code" : "name", trimmedSearchValue);
      }
    }, 1000);
  
    return () => {
      clearTimeout(searchTimeout);
      setSearchCompleted(false);
    };
  }, [searchValue, pageIndex, searchCompleted]); 

  const handleSearchChange = (e : any) => {
    setSearchValue(e.target.value);
    setRetrySearch(false);
  };

  const clearSearchInput = () => {
    setSearchValue("");
    setSearchType("name");
    setRetrySearch(false);
  };

  const getApiData = async (pageIndex =1 , searchType ="", searchText ="") => {
    let startRecord = (pageIndex - 1) * pageSize + 1;
    let endRecord = pageIndex * pageSize;

    const credentials = Cookies.get("Credentials")?.split("|") || [];
    const casesRequest = {
      UserInfo: {
        Username: credentials[0],
        Password: credentials[1],
      },
      pRequest: {
        Status: "Prospect",
        SearchType: searchType,
        SearchText: searchText,
        startDate: "2007-01-01",
        endDate: "2025-01-01",
        recordStartNo: startRecord,
        recordEndNo: endRecord,
        OrderType: [{ OrderOn: "code", OrderFlow: "desc" }],
      },
    };

    const request = {
      method: "POST",
      body: JSON.stringify(casesRequest),
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/SearchCasesBySearchTypeAndUserPermissionsJSONV2", request);
      const data = await response.json();
      if (data && data.d && data.d.Records.length > 0) {
        setGridData(data);
        setSearchCompleted(true);
      } else if (data && data.d && data.d.Records.length === 0 && searchType === "name") {
        setSearchType("code");
        setSearchCompleted(false); 
        getApiData(pageIndex, "code", searchText);
      } else {
        setGridData(null);
        setSearchCompleted(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setSearchCompleted(false);
    }
  };

  useEffect(() => {
    getApiData(pageIndex, searchType, searchValue);
  }, [pageIndex, searchType, searchValue]);

  function LoadPage(offset: number) {
    if (pageIndex + offset !== 0) {
      let newPageIndex = pageIndex + offset;
      setPageIndex(newPageIndex);
      getApiData(newPageIndex);
    }
  }

  function prevButtonStyles() {
    if (pageIndex === 1) {
      return "page-previous-disabled";
    } else {
      return "page-previous";
    }
  }

  function FormatCurrency(price: number) {
    if (price != null)
      return price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      });

    return "";
  }

  return (
   <>
      <CasesTopBar />
      <CasesBreadcrumbs pageName="Cases" />
      <div className="p-2 grid-container">
        <div className="search-input-container">
          <img src={searchIcon} alt="Search" className="search-icon" />
          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search by name or case number"
            className="search-input"
          />
          {searchValue && (
            <img
              className="clear-val-btn"
              src={xClose}
              alt="Clear input"
              onClick={clearSearchInput}
            />
          )}
        </div>
        <div className="grid-wrapper">
          <table>
            <tbody>
              <tr className="grid-top-table">
                <th>Case Ref.</th>
                <th>Date</th>
                <th>Customer</th>
                <th>Term</th>
                <th>Total amount</th>
                <th>Status</th>
              </tr>
              {gridData != null ? (
                gridData.d.Records.map((grid, index) => (
                  <tr key={index} onClick={() => fetchDetails(grid)} className="grid-record">
                    <td>{grid.JobReference}</td>
                    <td>{moment(grid.DateCreated).format("D MMM yyyy")}</td>
                    <td>{grid.CustomerNames}</td>
                    <td>{grid.Term}</td>
                    <td>{FormatCurrency(grid.LoanAmount)}</td>
                    <td>{grid.JobStatus}</td>
                  </tr>
                ))
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
        {gridData != null ? (
          <div className="page-control-wrapper">
            <div className="current-page-wrapper">
              <span>
                Showing {(pageIndex - 1) * pageSize + 1}-{(pageIndex - 1) * pageSize + gridData.d.Records.length}
              </span>
            </div>
            <div className="page-steps">
              <p onClick={() => LoadPage(-1)} className={prevButtonStyles()}>
                Prev
              </p>
              <p onClick={() => LoadPage(1)} className="page-next">
                Next
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Cases;